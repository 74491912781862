// PasswordCheck.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PasswordCheck = () => {
 const [password, setPassword] = useState('');
 const navigate = useNavigate();

 const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'adminuser1234') {
      localStorage.setItem('authTimestamp', new Date().toISOString());
      navigate('/add-auction');
    } else {
      alert('Incorrect password. Please try again.');
    }
 };

 return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-black min-h-96 p-3 rounded-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-50">
            Enter Password
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md px-4 py-2 text-gray-900"
            placeholder="Enter password"
          />
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
 );
};

export default PasswordCheck;