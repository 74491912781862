// NewAuctionForm.jsx
import React, { useEffect, useState } from "react";

const NewAuctionForm = ({ onSubmit, isNewAuctionOpen, setNewAuctionOpen }) => {
  const [domainName, setDomainName] = useState("");
  const [startingBid, setStartingBid] = useState(99);
  const [currency, setCurrency] = useState("EUR");
  const [currentAuctionPrice, setCurrentAuctionPrice] = useState(0);
  const [endTime, setEndTime] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Calculate the end time  7 days from now
    const endTimeDate = new Date();
    endTimeDate.setDate(endTimeDate.getDate() + 7);
    const endTimeString = endTimeDate.toISOString().slice(0, 16);
    onSubmit({ domainName, startingBid, currency, endTime: endTimeString });
  };

  useEffect(() => {
    // Calculate the end time  7 days from now
    const endTimeDate = new Date();
    endTimeDate.setDate(endTimeDate.getDate() + 7);
    const endTimeString = endTimeDate.toISOString().slice(0, 16);
    setEndTime(endTimeString);
  }, []);

  return (
    <>
      {isNewAuctionOpen && (
        <div
          id="popup-modal"
          tabindex="-1"
          className="modal m-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full h-screen pt-[10%]"
        >
          <div className="relative w-full max-w-screen-md h-[calc(100%-5rem)] max-h-full mx-auto overflow-x-hidden overflow-y-auto modal-content my-auto">
            <div className="relative rounded-lg shadow bg-[#282c34]">
              <h4 className="py-6">Add New Auction</h4>
              <button
                onClick={() => {
                  setNewAuctionOpen(false);
                }}
                type="button"
                className="absolute top-3 end-2.5 text-[#1FE2A5] bg-transparent hover:text-white rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-[#1FE2A5]"
                data-modal-hide="popup-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0  0  14  14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1  1  6  6m0  0  6  6M7  7l6-6M7  7l-6  6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-4 md:p-5 text-left">
                <form
                  onSubmit={handleSubmit}
                  className="flex gap-y-4 flex-col justify-center items-center"
                >
                  <label className="block w-full px-4 my-2">
                    Domain Name:
                    <input
                      className="block w-full px-4 my-2 h-10 rounded bg-black text-white"
                      type="text"
                      value={domainName}
                      onChange={(e) => setDomainName(e.target.value)}
                    />
                  </label>
                  <label className="block w-full px-4 my-2">
                    Starting Bid:
                    <input
                      className="block w-full px-4 my-2 h-10 rounded bg-black text-white"
                      type="number"
                      value={startingBid}
                      onChange={(e) => setStartingBid(e.target.value)}
                    />
                  </label>
                  <label className="block w-full px-4 my-2">
                    Currency:
                    <select
                      className="block w-full px-4 my-2 h-10 rounded bg-black text-white"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <option value="EUR">EUR</option>
                      <option value="USD">USD</option>
                    </select>
                  </label>
                  <label className="block w-full px-4 my-2">
                    Reserve Price:
                    <input
                      className="block w-full px-4 my-2 h-10 rounded bg-black text-white"
                      type="number"
                      value={currentAuctionPrice}
                      onChange={(e) => setCurrentAuctionPrice(e.target.value)}
                    />
                  </label>
                  <label className="block w-full px-4 my-2">
                    End Time:
                    <input
                      className="block w-full px-4 my-2 h-10 rounded bg-black text-white"
                      type="datetime-local"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                    />
                  </label>
                  <button
                    type="submit"
                    className="rounded-3xl submit-btn px-5 py-1 shadow-lg hover:shadow-none"
                  >
                    Add Auction
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewAuctionForm;
