
export const customStyles = {
  rows: {
    style: {
      color: "white",
    }
  },
  headCells: {
    style: {
      background: "black",
      color: "#1FE2A5",
      // border:'1px solid red',
      padding:"5px"
    },
  },
  cells: {
    style: {
      background: "black",
      color: "white",
      // border:'1px solid red',
      padding:"5px"
    },
  },
};

export function convertUnixTimestamp(timestamp) {
  if (!timestamp) {
    return 'Invalid timestamp';
  }

  const isIso8601 = timestamp.includes('T');
  const timeInSeconds = isIso8601 ? new Date(timestamp).getTime() / 1000 : Number(timestamp);

  if (timeInSeconds === 0) {
    return '0d, 0h, 0m';
  }

  const currentTime = new Date();
  const timeDifference = (timeInSeconds * 1000) - currentTime.getTime();

  if (timeDifference < 0) {
    const days = Math.floor(-timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((-timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((-timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    return <span className="text-red-600 text-sm">Expired {days > 0 && `${days}d,`} {hours}h, {minutes}m ago</span>;
  } else {
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    return `${days > 0 ? `${days}d` : ''} ${hours}h, ${minutes}m`
  }
}

export const convertToDateTime = (timestamp) => {
  let unixTimestampInMilliseconds;
  if (!timestamp) {
    return "Invalid timestamp";
  }

  if (timestamp.includes('T')) { // Check if the timestamp is in ISO 8601 format
    unixTimestampInMilliseconds = new Date(timestamp).getTime();
  } else {
    unixTimestampInMilliseconds = Number(timestamp) * 1000;
  }

  const dateObject = new Date(unixTimestampInMilliseconds);
  const day = String(dateObject.getDate()).padStart(2, '0');
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); //January is 0!
  const year = dateObject.getFullYear();

  return `${month}/${day}/${year} ${dateObject.toLocaleTimeString()}`;
};
export const isValidEmail = (email) => {
  // Simple regex for email validation
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};