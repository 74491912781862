// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DomainDetailPage from './pages/DomainDetailPage';
import Home from './pages';
import NotFoundPage from './pages/NotFound';
import AuctionContext from './context/AuctionContext';
import AddAuction from './pages/AddAuction';
import PasswordCheck from './componets/PasswordCheck';
import ProtectedRoute from './pages/ProtectedRoute';

function App() {
 const [selectedAuction, setSelectedAuction] = useState(null);

 return (
    <AuctionContext.Provider value={{ selectedAuction, setSelectedAuction }}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/domain/:domainName" element={<DomainDetailPage />} />
          <Route path="/add-auction" element={<ProtectedRoute />} /> {/* Use ProtectedRoute here */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </AuctionContext.Provider>
 );
}

export default App;