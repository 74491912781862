import React, { useCallback, useContext, useEffect, useState } from "react";
import AuctionContext from "../context/AuctionContext";
import Header from "../componets/Header";
import Notification from "../componets/ShowMessage";
import Footer from "../componets/Footer";
import logo from "../asset/final-02.png";
import logoDark from "../asset/logo.png";
import auction from "../asset/auction.png";
import success from "../asset/success.png";
import AuctionOverview from "../componets/AuctionOverview";
import CSVReader from "../componets/CSVReader";
import Modal from "react-modal";
import { apiBaseUrl } from "../utils";
import axios from "axios";
import { isValidEmail } from "../utils/csvreader";

// Ensure that the modal is accessible to screen readers
// Modal.setAppElement("#root");

const AddAuction = () => {
  const { setSelectedAuction } = useContext(AuctionContext);
  const [selectedAuction, setSelectedAuctionState] = useState(null);
  const [subscribed, setSubscribed] = useState(false);
  const [newBid, setNewBid] = useState(
    (Number(selectedAuction?.Bids) || 90) + 10
  ); // Default increment is  10
  const [userEmail, setUserEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [timeRemaining, settimeRemaining] = useState({
    days: "",
    hours: "",
    minutes: "",
  });
  // const { domain, Bids, currency } = selectedAuction;
  const domain = selectedAuction?.domain;
  const Bids = selectedAuction?.Bids;
  const currency = selectedAuction?.currency;
  const endTime = selectedAuction?.["end-timestamp"];
  const currentBid = Bids;
  // const endTime = selectedAuction["end-timestamp"];
  const handleSubscribe = () => {
    setSubscribed(true);
  };

  const calculateTimeRemaining = (endTime) => {
    const now = new Date();
    const auctionEndTime = new Date(endTime).getTime();
    const difference = auctionEndTime - now;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    return { days, hours, minutes };
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleBidSubmit = async () => {
    const normalizeData = {
      domain: domain,
      currency: currency,
      "current-auction-price": newBid,
      "end-timestamp": endTime,
      Bids: newBid,
      "Seller-Country": "USA",
      ActiveSince: "1990",
      VAT: "False",
    };

    try {
      // Make a POST request to the server to add the new auction
      const addAuctionResponse = await axios.post(
        `${apiBaseUrl}/api/add-auction`,
        normalizeData
      );

      if (addAuctionResponse.status === 200) {
        console.log(addAuctionResponse);
      } else {
        throw new Error("Error adding auction");
      }
    } catch (error) {
      console.error("Error adding auction:", error);
    }

    if (!isValidEmail(userEmail)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const subscribeResponse = await axios.post(`${apiBaseUrl}/subscribe`, {
        email: userEmail,
      });
      if (subscribeResponse.status === 200) {
        handleSubscribe();
        // clear email
        setUserEmail("");
        openModal();
      }
    } catch (error) {
      console.error(error);
      return; // Return early if there's an error with the subscribe API call
    }
  };
  useEffect(() => {
    if (endTime) {
      settimeRemaining(calculateTimeRemaining(endTime));
    }
  }, [endTime, selectedAuction]);

  // if (!selectedAuction) {
  //   return <div>No auction data found.</div>;
  // }

  const [showEmail, setshowEmail] = useState(false);
  useEffect(() => {
    if (newBid > currentBid) {
      setshowEmail(true);
    } else {
      setshowEmail(false);
    }
  }, [newBid, currentBid]);

  useEffect(() => {
    // Retrieve the selected auction data from localStorage
    const storedAuction = localStorage.getItem("selectedAuction");
    if (storedAuction) {
      const auctionData = JSON.parse(storedAuction);
      setSelectedAuctionState(auctionData);
      setSelectedAuction(auctionData); // Update the context with the retrieved data
    }
  }, []);

  return (
    <div>
      <Header color={"main-bg"} handleSubscribe={handleSubscribe} />
      <Notification show={subscribed} />

      <div className="min-h-screen flex flex-col items-center justify-center py-10 ">
        <h2 className="text-2xl font-semibold mb-4">Auction Overview</h2>
        <div className="text-center w-full">
          <CSVReader />
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
            </div>

            {/* Modal content */}
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex items-center justify-center">
                  <div className="mt-3">
                    {/* <img
                      src={logoDark}
                      alt="AUCDOM Logo"
                      className="w-56 mb-4 mx-auto"
                    /> */}
                    <div className="mt-2 text-center">
                      <p className="text-lg text-center text-white">
                        Successfully Bid! You will receive an email shortly to
                        confirm the Bid.
                      </p>
                    </div>

                    <img
                      src={success}
                      alt="success"
                      className="w-36 my-10 mb-4 mx-auto"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full max-w-56 h-10 button-clasic mb-5"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default AddAuction;
