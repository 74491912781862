// ProtectedRoute.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddAuction from './AddAuction';
import PasswordCheck from '../componets/PasswordCheck';

const ProtectedRoute = () => {
 const navigate = useNavigate();

 const isAuthenticated = () => {
    const timestamp = localStorage.getItem('authTimestamp');
    if (!timestamp) return false;
    const now = new Date();
    const authTime = new Date(timestamp);
    const diffInMinutes = (now - authTime) / 1000 / 60;
    return diffInMinutes < 60;
 };

 useEffect(() => {
    if (isAuthenticated()) {
      navigate('/add-auction');
    }
 }, []);

 return isAuthenticated() ? <AddAuction /> : <PasswordCheck />;
};

export default ProtectedRoute;