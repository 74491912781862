import React, { useCallback, useContext, useEffect, useState } from "react";
import AuctionContext from "../context/AuctionContext";
import Header from "../componets/Header";
import Notification from "../componets/ShowMessage";
import Footer from "../componets/Footer";
import logo from "../asset/final-02.png";
import logoDark from "../asset/logo.png";
import auction from "../asset/auction.png";
import success from "../asset/success.png";
import AuctionOverview from "../componets/AuctionOverview";
import CSVReader from "../componets/CSVReader";
import Modal from "react-modal";
import { apiBaseUrl } from "../utils";
import axios from "axios";
import { isValidEmail } from "../utils/csvreader";
import { useNavigate } from "react-router-dom";

// Ensure that the modal is accessible to screen readers
// Modal.setAppElement("#root");

const DomainDetailPage = () => {
  const { setSelectedAuction } = useContext(AuctionContext);
  const [selectedAuction, setSelectedAuctionState] = useState(null);
  const [subscribed, setSubscribed] = useState(false);
  const [newBid, setNewBid] = useState(
    (Number(selectedAuction?.Bids) || 90) + 10
  ); // Default increment is  10
  const [userEmail, setUserEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [timeRemaining, settimeRemaining] = useState({
    days: "",
    hours: "",
    minutes: "",
  });
  // const { domain, Bids, currency } = selectedAuction;
  const domain = selectedAuction?.domain;
  const Bids = selectedAuction?.Bids;
  const currency = selectedAuction?.currency;
  const endTime = selectedAuction?.["end-timestamp"];
  const currentBid = Bids;
  // const endTime = selectedAuction["end-timestamp"];
  const handleSubscribe = () => {
    setSubscribed(true);
  };

  const calculateTimeRemaining = (endTime) => {
    const now = new Date();
    const auctionEndTime = new Date(endTime).getTime();
    const difference = auctionEndTime - now;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    return { days, hours, minutes };
  };
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  const handleBidSubmit = async () => {
    // Check if the new bid is at least 10 higher than the current bid
    if (newBid <= currentBid) {
      setError("Your bid must be at least 10 higher than the current bid.");
      return;
    }

    const normalizeData = {
      domain: domain,
      currency: currency,
      "current-auction-price": newBid,
      "end-timestamp": endTime,
      Bids: newBid,
      "Seller-Country": "USA",
      ActiveSince: "1990",
      VAT: "False",
    };

    try {
      // Make a POST request to the server to update the auction
      const updateAuctionResponse = await axios.post(
        `${apiBaseUrl}/api/update-auction`,
        normalizeData
      );

      if (updateAuctionResponse.status === 200) {
        console.log(updateAuctionResponse);
      } else {
        throw new Error("Error updating auction");
      }
    } catch (error) {
      console.error("Error updating auction:", error);
      setError(
        "An error occurred while updating the auction. Please try again."
      );
      return;
    }

    if (!isValidEmail(userEmail)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      // Make a POST request to the server to log the bid details
      const logBidResponse = await axios.post(`${apiBaseUrl}/api/log-bid`, {
        domain: domain,
        newBid: newBid,
        email: userEmail,
      });

      if (logBidResponse.status === 200) {
        // Handle successful bid submission and logging
        handleSubscribe();
        // clear email
        setUserEmail("");
        openModal();
      } else {
        throw new Error("Error logging bid");
      }
    } catch (error) {
      console.error("Error logging bid:", error);
      setError("An error occurred while logging your bid. Please try again.");
      return;
    }
  };
  // Helper function to get the currency symbol
  // function getCurrencySymbol(currency) {
  //   switch (currency) {
  //      case 'USD':
  //        return '$';
  //      case 'EUR':
  //        return '€';
  //      // Add more cases as needed for other currencies
  //      default:
  //        return currency; // Return the currency code if no symbol is found
  //   }
  //  }

  // const currencySymbol = getCurrencySymbol(currency);

  useEffect(() => {
    if (endTime) {
      settimeRemaining(calculateTimeRemaining(endTime));
    }
  }, [endTime, selectedAuction]);

  // if (!selectedAuction) {
  //   return <div>No auction data found.</div>;
  // }

  const [showEmail, setshowEmail] = useState(false);
  useEffect(() => {
    if (newBid > currentBid) {
      setshowEmail(true);
    } else {
      setshowEmail(false);
    }
  }, [newBid, currentBid]);

  useEffect(() => {
    // Retrieve the selected auction data from localStorage
    const storedAuction = localStorage.getItem("selectedAuction");
    if (storedAuction) {
      const auctionData = JSON.parse(storedAuction);
      setSelectedAuctionState(auctionData);
      setSelectedAuction(auctionData); // Update the context with the retrieved data
    }
  }, []);

  return (
    <div>
      <Header color={"main-bg"} handleSubscribe={handleSubscribe} />
      <Notification show={subscribed} />

      <div className="min-h-screen flex flex-col items-center justify-center py-10 bg-black">
        <div className="content px-10 w-full">
          <div>
            {/* <img src={logoDark} alt="AUCDOM Logo" className="w-56 mb-4" /> */}
            <img
              className="w-56 mb-4"
              src={process.env.PUBLIC_URL + "/images/logo.png"}
              alt="Logo"
            />
            <h2 className="text-white text-xl font-bold">
              Easy Bidding with your E-Mail - no registration necessary!
            </h2>
          </div>

          <div className=" max-w-lg mx-auto w-full border-2 border-green-500 my-10 px-5 py-4 rounded-md">
            <div className="my-7">
              <h6 className="font-bold text-lg text-white text-center">
                Auction Details
              </h6>
            </div>
            <div className="my-5 max-w-lg text-white">
              <h1 className="text-4xl font-bold mb-4">Domain: {domain}</h1>
              <div className="bid-info mb-4">
                <span className="text-white text-lg font-bold">
                  Current Bid:{" "}
                </span>
                <span className="text-xl font-semibold text-[#999999]">
                  {currency}
                  {"  "}
                  {currentBid}
                </span>
              </div>
              <div className="my-4">
                <div className="flex flex-col gap-5 justify-between">
                  <div className="flex flex-col">
                    <span className="text-lg font-bold">
                      Auctions Ends at:{" "}
                    </span>
                    <span className="text-lg font-semibold  text-[#999999]">
                      {" "}
                      {new Date(endTime).toLocaleString()}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-bold text-xl">Time Remaining:</span>
                    <span className="text-lg font-semibold  text-[#999999]">
                      {timeRemaining.days} days {timeRemaining.hours} hours{" "}
                      {timeRemaining.minutes} minutes
                    </span>
                  </div>
                </div>
              </div>
              <form action="">
                <div className="flex flex-col gap-y-2">
                  <label className="text-lg font-bold" htmlFor="bids">
                    Enter your Bid
                  </label>
                  <input
                    type="number"
                    value={newBid}
                    onChange={(e) => setNewBid(Number(e.target.value) + 10)}
                    className="border border-white transition-all delay-200 focus:shadow-xl bg-black text-white   px-5 h-16 py-2 w-full focus:outline-none my-4"
                    placeholder="Enter new bid"
                  />
                </div>
                {error && <span className="text-red-400">{error}</span>}
                {showEmail && (
                  <input
                    type="email"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    className="border border-white transition-all delay-200 focus:shadow-xl bg-black text-white  h-16 px-5 py-2  w-full text-left focus:outline-none mb-4 placeholder:text-gray-400 mt-4"
                    placeholder="Your E-Mail"
                  />
                )}
                <div className="flex justify-ends">
                  <button
                    type="button"
                    onClick={handleBidSubmit}
                    className="bg-black transition-all delay-150 hover:shadow-2xl h-16   text-white px-4 py-2  rounded-md focus:outline-none main-bg hover:opacity-85 w-full mt-5"
                  >
                    Submit Bid
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
            </div>

            {/* Modal content */}
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex items-center justify-center">
                  <div className="mt-3">
                    {/* <img
                      src={logoDark}
                      alt="AUCDOM Logo"
                      className="w-56 mb-4 mx-auto"
                    /> */}
                    <div className="mt-2 text-center">
                      <p className="text-lg text-center text-white">
                        Successfully Bid! You will receive an email shortly to
                        confirm the Bid.
                      </p>
                    </div>

                    <img
                      src={success}
                      alt="success"
                      className="w-36 my-10 mb-4 mx-auto"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full max-w-56 h-10 button-clasic mb-5"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default DomainDetailPage;
