import React, { useState, useEffect, useContext } from "react";
import Papa from "papaparse";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import DataTable from "react-data-table-component";
import sedoicon from "../asset/sedo.png";
import godaddyicon from "../asset/godaddy.svg";
import aucdomicon from "../asset/logo.jpg";
import ebayicon from "../asset/ebay.svg";
import nicsellicon from "../asset/nicsell.png";
import csvFile1 from "../data/sedo_output.csv";
import csvFile2 from "../data/godaddy_output.csv";
import csvFile3 from "../data/ebay_output.csv";
import csvFile4 from "../data/nicsell_output.csv";
import csvFile5 from "../data/aucdom.csv";
// import EditForm from "./Form";
import {
  convertToDateTime,
  convertUnixTimestamp,
  customStyles,
} from "../utils/csvreader";
import NewAuctionForm from "./NewAuctionForm";
import { apiBaseUrl } from "../utils";
import { Link, useNavigate } from "react-router-dom";
import AuctionContext from "../context/AuctionContext";
import { useLocation } from "react-router-dom";

const CSVReader = () => {
  const [data, setData] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState(["ActiveSince", "VAT"]);
  const [selectedRecord, setSelectedRecord] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const isHomepage = location.pathname === "/";

  const [showForm, setShowForm] = useState(false);

  const { setSelectedAuction } = useContext(AuctionContext);
  const navigate = useNavigate();

  const handleAddAuction = async (newAuction) => {
    const normalizeData = {
      domain: newAuction.domainName,
      currency: newAuction.currency,
      "current-auction-price": 6,
      "end-timestamp": newAuction.endTime,
      Bids: newAuction.startingBid,
      "Seller-Country": "USA",
      ActiveSince: "1990",
      VAT: "False",
    };
    // return
    try {
      // Make a POST request to the server to add the new auction
      const response = await fetch(`${apiBaseUrl}/api/add-auction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(normalizeData),
      });

      if (response.ok) {
        // Optionally, you can update the local state here if you want to reflect the changes immediately
        const normalizeNewAuctionData = {
          domain: newAuction.domainName,
          currency: newAuction.currency,
          "current-auction-price": 6,
          "end-timestamp": newAuction.endTime,
          Bids: newAuction.startingBid,
          "Seller-Country": "USA",
          ActiveSince: "1990",
          VAT: "False",
          source: "aucdom",
          hyperLink: newAuction.domainName,
        };
        setData((prevData) => [...prevData, normalizeNewAuctionData]);
        setShowForm(false); // Close the form after submission
      } else {
        throw new Error("Error adding auction");
      }
    } catch (error) {
      console.error("Error adding auction:", error);
    }
  };

  useEffect(() => {
    const parseCSV = async (file) => {
      return new Promise((resolve) => {
        Papa.parse(file, {
          download: true,
          header: true,
          complete: function (results) {
            resolve(results.data);
          },
        });
      });
    };

    const loadData = async () => {
      const sedoData = await parseCSV(csvFile1);
      const godaddyData = await parseCSV(csvFile2);
      const ebayData = await parseCSV(csvFile3);
      const nicsellData = await parseCSV(csvFile4);
      const aucdomData = await parseCSV(csvFile5);

      const sedoDataNormalized = sedoData.map((item) => ({
        ...item,
        source: "sedo",
        hyperLink: `https://sedo.com/search/details/?domain=${item["domain"]}`,
      }));

      // Normalize godaddyData and add source property
      const godaddyDataNormalized = godaddyData.map((item) => ({
        ...item,
        domain: item["Domain"],
        currency: item["Currency"],
        "Seller-Country": "USA",
        VAT: "False",
        ActiveSince: "1990",
        OfferViews: item["offerViews"],
        ReservePriceRange: item["Reserve"],
        source: "godaddy",
        Watcher: item["offerViews"],
        hyperLink: item["URL"],
      }));

      const normalizedEbayData = ebayData.map((item) => ({
        ...item,
        domain: item["Domain"],
        currency: item["Currency"],
        "Seller-Country": "USA",
        VAT: "False",
        ActiveSince: "1990",
        "current-auction-price": item["Current Bid"],
        Bids: item["Bid Count"],
        "end-timestamp": item["EndDate"],
        OfferViews: item["offerViews"],
        "Reserve Price": item["Reserve Price"] ? item["Reserve Price"] : "0",
        source: "ebay",
        hyperLink: item["URL"],
      }));

      const normalizedNicsellData = nicsellData.map((item) => ({
        // ...item,
        domain: item["Domain"],
        currency: "EUR",
        "Seller-Country": "USA",
        VAT: "False",
        ActiveSince: "1990",
        "current-auction-price": item["Price"],
        Bids: item["Bids"],
        "end-timestamp": item["Ending"],
        OfferViews: item["offerViews"],
        "Reserve Price": item["Reserve Price"] ? item["Reserve Price"] : "0",
        source: "nicsell",
        hyperLink: item["Url"],
      }));
      const normalizeAucdomData = aucdomData.map((item) => ({
        ...item,
        source: "aucdom",
        hyperLink: `${item["domain"]}`,
      }));
      const combinedData = [
        ...sedoDataNormalized,
        ...godaddyDataNormalized,
        ...normalizedEbayData,
        ...normalizedNicsellData,
        ...normalizeAucdomData,
      ];

      const currentTime = new Date().getTime();

      const filteredDataForExpiration = combinedData.filter((item) => {
        const auctionEndTime = item["end-timestamp"]?.includes("T")
          ? new Date(item["end-timestamp"]).getTime()
          : Number(item["end-timestamp"]) * 1000;
        const hoursSinceEnd = (currentTime - auctionEndTime) / (1000 * 60 * 60);
        return hoursSinceEnd <= 6;
      });

      // Sort data by price in descending order
      // const sortedData = [...filteredData].sort((a, b) => Number(b['current-auction-price']) - Number(a['current-auction-price']));

      // Sort data by time remaining in ascending order
      const sortedData = [...filteredDataForExpiration].sort((a, b) => {
        let timeA, timeB;

        if (a["end-timestamp"].includes("T")) {
          // If the timestamp includes 'T', it's an ISO date string
          timeA = new Date(a["end-timestamp"]).getTime();
        } else {
          // Otherwise, it's a Unix timestamp
          timeA = Number(a["end-timestamp"]) * 1000;
        }

        if (b["end-timestamp"].includes("T")) {
          // If the timestamp includes 'T', it's an ISO date string
          timeB = new Date(b["end-timestamp"]).getTime();
        } else {
          // Otherwise, it's a Unix timestamp
          timeB = Number(b["end-timestamp"]) * 1000;
        }

        return timeA - timeB;
      });

      // Function to get the timestamp from the item
      const getTimestamp = (item) => {
        return item["end-timestamp"]?.includes("T")
          ? new Date(item["end-timestamp"]).getTime()
          : Number(item["end-timestamp"]) * 1000;
      };

      // Group items by their end timestamp
      const groupedByEndTime = filteredDataForExpiration.reduce((acc, item) => {
        const timestamp = getTimestamp(item);
        if (!acc[timestamp]) {
          acc[timestamp] = [];
        }
        acc[timestamp].push(item);
        return acc;
      }, {});

      // Now we have a map of arrays grouped by end timestamp
      // We can sort each group by highest bid if there are multiple items with the same timestamp
      Object.keys(groupedByEndTime).forEach((timestamp) => {
        if (groupedByEndTime[timestamp].length > 1) {
          groupedByEndTime[timestamp].sort((a, b) => {
            const bidA = Number(a["Bids"]);
            const bidB = Number(b["Bids"]);
            return bidB - bidA; // Sort in descending order of highest bid
          });
        }
      });

      // Extract the groups into two arrays
      const itemsWithSameEndTime = [];
      const itemsWithDifferentEndTime = [];

      Object.keys(groupedByEndTime).forEach((timestamp) => {
        if (groupedByEndTime[timestamp].length > 1) {
          itemsWithSameEndTime.push(...groupedByEndTime[timestamp]);
        } else {
          itemsWithDifferentEndTime.push(...groupedByEndTime[timestamp]);
        }
      });

      // Sort the items with different end times by end time
      itemsWithDifferentEndTime.sort((a, b) => {
        const timeA = getTimestamp(a);
        const timeB = getTimestamp(b);
        return timeB - timeA; // Sort in ascending order of end time
      });
      // Finally, concatenate the two sorted arrays
      const finalSortedData = [
        ...itemsWithSameEndTime,
        ...itemsWithDifferentEndTime,
      ];

      let groupedData = itemsWithSameEndTime;

      // Get the timestamps and sort them
      let sortedTimestamps = Object.keys(groupedData).sort((a, b) => a - b);

      // Create a new array with the sorted groups
      let sortedGroupedData = sortedTimestamps.map((timestamp) => ({
        [timestamp]: groupedData[timestamp],
      }));

      // Flatten the array of groups into one array
      let mergedData = [
        ...sortedGroupedData.flatMap((group) => Object.values(group)[0]),
        ...itemsWithDifferentEndTime,
      ];

      setData(mergedData);
      // setData(combinedData);
    };
    loadData();
  }, []);

  // console.log(data, 'data');
  const handleHideColumn = (columnSelector) => {
    setHiddenColumns([...hiddenColumns, columnSelector]);
  };

  const handleShowColumn = (columnSelector) => {
    setHiddenColumns(hiddenColumns.filter((col) => col !== columnSelector));
  };

  const handleDomainClick = (auction) => {
    const url = auction.domain;
    setSelectedAuction(auction);
    localStorage.setItem("selectedAuction", JSON.stringify(auction));
    navigate(`/domain/${url}`);
    // Navigate to the DomainDetailPage
    // You can use history.push or a Link component to navigate
  };

  // Add the AUCDOM icon to the sourceIcons object
  const sourceIcons = {
    ebay: ebayicon,
    nicsell: nicsellicon,
    godaddy: godaddyicon,
    sedo: sedoicon,
    aucdom: aucdomicon,
  };

  const handleRemoveAuction = async (auctionId) => {
    try {
      const response = await fetch(
        `${apiBaseUrl}/api/remove-auction/${auctionId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        // Remove the auction from the local state
        setData((prevData) =>
          prevData.filter((auction) => auction.id !== auctionId)
        );
        console.log("Auction removed successfully");
      } else {
        throw new Error("Error removing auction");
      }
    } catch (error) {
      console.error("Error removing auction:", error);
    }
  };

  // const hiddenColumns = ['Active Since']; // add column names to hide here
  const columns = [
    {
      name: "Source",
      selector: "source",
      maxWidth: "100px",
      minWidth: "100px",
      cell: (row) => (
        <>
          {row.source !== "aucdom" ? (
            <a target={`_blank`} href={row.hyperLink}>
              <img
                className="w-14 h-14 object-contain"
                src={sourceIcons[row.source]}
                alt={row.source}
              />
            </a>
          ) : (
            <Link
              to={{
                pathname: `/domain/${row.domain}`,
                state: { auctionData: row },
              }}
              className="hover:underline text-[#1FE2A5] hover:text-white"
            >
              <img
                className="w-14 h-14 object-contain"
                src={sourceIcons[row.source]}
                alt={row.source}
              />
            </Link>
          )}
        </>
      ),
    },
    {
      name: "Domain",
      selector: "domain",
      maxWidth: "fit-content",
      minWidth: "150px",
      sortable: true,
      cell: (row) => (
        <>
          {row.source !== "aucdom" ? (
            <a
              target={`${row.source !== "aucdom" ? "_blank" : ""}`}
              className="hover:underline text-[#1FE2A5] hover:text-white"
              href={row.hyperLink}
            >
              {row.domain || (
                <div className="w-2 h-[1px] bg-white mx-auto"></div>
              )}
            </a>
          ) : (
            <button
              onClick={() => handleDomainClick(row)}
              className="hover:underline text-[#1FE2A5] hover:text-white"
            >
              {row.domain || (
                <div className="w-2 h-[1px] bg-white mx-auto"></div>
              )}
            </button>
          )}
        </>
      ),
    },
    {
      name: "Current Auction Price",
      selector: "currencyAndPrice",
      maxWidth: "fit-content",
      minWidth: "171px",
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const valueA = Number(rowA["current-auction-price"]);
        const valueB = Number(rowB["current-auction-price"]);
        return valueA - valueB;
      },
      cell: (row) => (
        <div className="flex items-center justify-between w-[80%]">
          {row.currency ? (
            <span>{row.currency}</span>
          ) : (
            <div className="w-2 h-[1px] bg-white mr-auto"></div>
          )}
          <span>{Number(row["current-auction-price"]).toLocaleString()}</span>
        </div>
      ),
    },
    {
      name: "End Timestamp",
      selector: "end-timestamp",
      maxWidth: "fit-content",
      minWidth: "165px",
      sortable: true,
      cell: (row) =>
        convertToDateTime(row["end-timestamp"]) || (
          <div className="w-2 h-[1px] bg-white mx-auto"></div>
        ),
      sortFunction: (rowA, rowB) => {
        const valueA = rowA["end-timestamp"]?.includes("T")
          ? new Date(rowA["end-timestamp"]).getTime() / 1000
          : Number(rowA["end-timestamp"]);
        const valueB = rowB["end-timestamp"]?.includes("T")
          ? new Date(rowB["end-timestamp"]).getTime() / 1000
          : Number(rowB["end-timestamp"]);
        return valueA - valueB;
      },
    },
    {
      name: "Time Remaining",
      selector: "end-timestamp",
      minWidth: "fit-content",
      minWidth: "175px",
      sortable: true,
      cell: (row) =>
        convertUnixTimestamp(row["end-timestamp"]) || (
          <div className="w-2 h-[1px] bg-white mx-auto"></div>
        ),
      sortFunction: (rowA, rowB) => {
        const valueA = rowA["end-timestamp"]?.includes("T")
          ? new Date(rowA["end-timestamp"]).getTime() / 1000
          : Number(rowA["end-timestamp"]);
        const valueB = rowB["end-timestamp"]?.includes("T")
          ? new Date(rowB["end-timestamp"]).getTime() / 1000
          : Number(rowB["end-timestamp"]);
        return valueA - valueB;
      },
    },
    {
      name: "Bids",
      selector: "Bids",
      minWidth: "fit-content",
      maxWidth: "78px",
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const valueA = Number(rowA["Bids"]);
        const valueB = Number(rowB["Bids"]);
        return valueA - valueB;
      },
      cell: (row) => {
        const Bids = Number(row.Bids);
        const formattedBids = isNaN(Bids) ? "-" : Bids.toLocaleString();

        return (
          <div className="flex items-center justify-end w-[80%]">
            {formattedBids !== "-" ? (
              <span>{formattedBids}</span>
            ) : (
              <div className="w-2 h-[1px] bg-white mx-auto"></div>
            )}
          </div>
        );
      },
    },
    {
      name: "Watcher/Views",
      selector: "Watcher",
      maxWidth: "fit-content",
      minWidth: "120px",
      sortable: true,
      cell: (row) => {
        const Watcher = Number(row.Watcher);
        const formattedWatcher = isNaN(Watcher)
          ? "-"
          : Watcher.toLocaleString();

        return (
          <div className="flex items-center justify-end w-[80%]">
            {formattedWatcher !== "-" ? (
              <span>{formattedWatcher}</span>
            ) : (
              <div className="w-2 h-[1px] bg-white ml-auto"></div>
            )}
          </div>
        );
      },
    },
    {
      name: "Bidders",
      selector: "Bidders",
      minWidth: "fit-content",
      maxWidth: "110px",
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const valueA = Number(rowA["Bidders"]);
        const valueB = Number(rowB["Bidders"]);
        return valueA - valueB;
      },
      cell: (row) => {
        const Bidders = Number(row.Bidders);
        const formattedBidders = isNaN(Bidders)
          ? "-"
          : Bidders.toLocaleString();

        return (
          <div className="flex items-center justify-end w-[70%]">
            {formattedBidders !== "-" ? (
              <span>{formattedBidders}</span>
            ) : (
              <div className="w-2 h-[1px] bg-white ml-auto"></div>
            )}
          </div>
        );
      },
    },
    {
      name: "EstimatedValue",
      selector: "EstimatedValue",
      minWidth: "fit-content",
      maxWidth: "150px",
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const valueA = Number(rowA["EstimatedValue"]);
        const valueB = Number(rowB["EstimatedValue"]);
        return valueA - valueB;
      },
      cell: (row) => {
        const EstimatedValue = Number(row.EstimatedValue);
        const formattedEstimatedValue = isNaN(EstimatedValue)
          ? "-"
          : EstimatedValue.toLocaleString();

        return (
          <div className="flex items-center justify-end w-[90%]">
            {formattedEstimatedValue !== "-" ? (
              <span>{formattedEstimatedValue}</span>
            ) : (
              <div className="w-2 h-[1px] bg-white mx-auto"></div>
            )}
          </div>
        );
      },
    },
    {
      name: "Reserve",
      selector: "ReservePriceRange",
      maxWidth: "fit-content", // Corrected from 'minWidth'
      minWidth: "170px",
      sortable: true,
      cell: (row) => {
        const reservePrice = row.ReservePriceRange;

        if (reservePrice && reservePrice === "0 - 0") {
          return "0";
        }

        const formattedReservePrice = reservePrice
          ? reservePrice.replace(/(\d)(?=(\d{3})+$)/g, "$1,")
          : null;
        return (
          (formattedReservePrice && (
            <div className="flex items-center justify-end w-[70%]">
              <span>{formattedReservePrice}</span>
            </div>
          )) || <div className="w-2 h-[1px] bg-white mx-auto"></div>
        );
      },
    },
    {
      name: "Seller Country",
      selector: "SellerCountry",
      minWidth: "fit-content",
      maxWidth: "200px",
      sortable: true,
      cell: (row) => row.SellerCountry || "USA",
    },
    {
      name: "Active Since",
      selector: "ActiveSince",
      minWidth: "fit-content",
      sortable: true,
      cell: (row) =>
        row.ActiveSince ? (
          row.ActiveSince.includes("Active since") ? (
            row.ActiveSince
          ) : (
            "Active since " + row.ActiveSince
          )
        ) : (
          <div className="w-2 h-[1px] bg-white mx-auto"></div>
        ),
    },
    {
      name: "VAT",
      selector: "VAT",
      maxWidth: "fit-content",
      minWidth: "200px",
      sortable: true,
      cell: (row) => row.VAT || "False",
    },
    ...(isHomepage
      ? []
      : [
          {
            name: "Remove",
            cell: (row) =>
              row.source === "aucdom" && (
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() => handleRemoveAuction(row.domain)} // Assuming each auction has a unique 'domain' property
                >
                  Remove
                </button>
              ),
            ignoreRowClick: true, // Prevents the row click event from triggering when clicking the button
            allowOverflow: true, // Allows the button to overflow the cell if needed
            button: true, // Indicates that this column is a button
          },
        ]),
  ]
    .map((column) => ({
      ...column,
      name: (
        <div className="flex gap-3">
          <span>{column.name}</span>
          <div className="z-20 flex items-center mr-1 w-3 text-center">
            <span
              className={
                hiddenColumns.includes(column.selector) ? "hidden" : ""
              }
              onClick={() => handleHideColumn(column.selector)}
            >
              <FaEyeSlash />
            </span>
          </div>
        </div>
      ),
    }))
    .filter((column) => !hiddenColumns.includes(column.selector));

  return (
    <div className="flex flex-col items-center justify-center bg-black rounded-lg w-full min-h-fit max-w-[1340px] mx-auto mb-10">
      <div className="bg-black w-full px-2 py-6 shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-white text-center ">
          Reverse (Dutch) Auctions
        </h2>

        {hiddenColumns.length > 0 && (
          <h6 className="text-sm">Click Domain to instantly buy on DAN (Godaddy)! Price will go down once or twice daily until minimum is reached (up to US$ 99)<br />
After the auction, all unsold names will be priced at a fixed $ 5,000 - so be quick and get a special deal!</h6>
        )}
        <div className="flex justify-end mb-4">
          {hiddenColumns.map((columnSelector) => (
            <>
              <div
                className="text-white cursor-pointer my-2 h-8 flex items-center mx-2 max-w-fit px-2 rounded-full py-2 hide-show-button"
                key={columnSelector}
                onClick={() => handleShowColumn(columnSelector)}
              >
                <FaEye className="mr-2" />
                <span className="text-sm capitalize">{columnSelector}</span>
              </div>
            </>
          ))}
        </div>

        <div className="w-full mb-4">
          {!isHomepage && (
            <button
              className="text-white cursor-pointer  my-2 flex items-center mx-2 max-w-fit ps-2 pe-3 rounded-full py-2 hide-show-button h-12 hover:opacity-75"
              onClick={() => setShowForm(true)}
            >
              <MdAdd className="mx-1" /> Add New Auction
            </button>
          )}
        </div>

        {showForm && (
          <NewAuctionForm
            isNewAuctionOpen={showForm}
            setNewAuctionOpen={setShowForm}
            onSubmit={handleAddAuction}
          />
        )}
        <DataTable
          className="w-full bg-black h-[70vh] max-h-[600px] overflow-y-auto "
          noHeader
          data={data}
          columns={columns}
          customStyles={customStyles}
          pagination
          highlightOnHover
          fixedHeader
          paginationPerPage={50}
          onRowClicked={(row) => {
            setIsOpen(true);
            setSelectedRecord(row);
          }}
        />
        {/* {selectedRecord && <EditForm setIsOpen={setIsOpen} isOpen={isOpen} data={data} selectedRecord={selectedRecord} setData={setData} />} */}
      </div>
    </div>
  );
};

export default CSVReader;
