import React, { useState } from "react";
import Header from "../componets/Header";
import Notification from "../componets/ShowMessage";
import CSVReader from "../componets/CSVReader";
import Footer from "../componets/Footer";

function Home() {
  const [subscribed, setSubscribed] = useState(false);

  const handleSubscribe = () => {
    setSubscribed(true);
  };
  return (
    <div className="App">
      <Header handleSubscribe={handleSubscribe} />
      <Notification show={subscribed} />
      <div className="pt-[120px] w-full">
        <CSVReader />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
