import React from 'react';

const Footer = () => {
    return (<>
        <div className="relative overflow-hidden w-full h-1">
            <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-green-500 to-green-500"></div>
        </div>
        <footer className="mt-0 text-center bg-black text-white min-h-32 flex flex-col items-center justify-center">
           <div className="flex justify-around w-full">
               <a href="mailto:info@aucdom.com" className="text-[#1FE2A5] text-lg hover:text-white hover:underline">Mail: info@aucdom.com</a>
               <a target ='_blank' href="https://form.aucdom.com/" className="text-[#1FE2A5] text-lg hover:text-white hover:underline">List Domain Auction (FREE!)</a>
               <span>(c) 2024 AUCDOM.COM</span>
           </div>
       </footer>
    </>);
};

export default Footer;