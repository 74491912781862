import React, { useEffect, useState } from 'react';

const Notification = ({ show }) => {
    const [visible, setVisible] = useState(false);
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        if (show) {
            setVisible(true);
            let timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        clearInterval(timer);
                        return 100;
                    }
                    const newProgress = oldProgress + 10;
                    return newProgress > 100 ? 100 : newProgress;
                });
            }, 500);

            const timerToHide = setTimeout(() => {
                setVisible(false);
            }, 5000); // Message will disappear after 5 seconds

            return () => {
                clearInterval(timer);
                clearTimeout(timerToHide);
            }; // Clear timeout if the component unmounts
        }
    }, [show]);


    if (!visible) return null;

    return (
        <div className="fixed bottom-4 left-0 right-2 flex flex-col items-center justify-center bg-gray-600 text-white p-4 rounded shadow-lg z-50 max-w-md ml-auto h-16">
            <div class="absolute -top-0 w-full bg-gray-200 rounded-full h-2 mb-4 dark:bg-gray-700">
                <div class="bg-[#1FE2A5] h-2 rounded-full transition-width duration-2000 ease-linear " style={{ width: `${progress}%` }}></div>
            </div>
            Successfully subscribed to our newsletter.
        </div>
    );
};

export default Notification;