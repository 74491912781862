export const apiBaseUrl = 'https://aucdom.com';

export const isAuthenticated = () => {
    const timestamp = localStorage.getItem('authTimestamp');
    if (!timestamp) return false;
    const now = new Date();
    const authTime = new Date(timestamp);
    const diffInMinutes = (now - authTime) / 1000 / 60;
    return diffInMinutes < 60;
 }
